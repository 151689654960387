/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply font-roboto;
  height: 100%;
  overflow: hidden;
}

.content {
  flex: 1;
  padding: 1rem;
  background: #f2f7f7;
}

.no-padding {
  padding: 0 !important;
}

.refresh-button {
  background-color: #0d6ea1;
  border: none;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.refresh-icon {
  filter: brightness(0) invert(1);
  width: 35px;
  height: 35px;
}

.add-button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button {
  border: none;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border-radius: 50%;
  overflow: hidden;
}

.avatar-header {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar-header img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}

::-webkit-scrollbar-track {
  border-radius: 24px !important;
}

::-webkit-scrollbar-thumb {
  background: #dbe3e8 !important;
  border-radius: 24px !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
